import gsap from 'gsap';

export function leaveBlog({ el, done }: { el: Element; done: () => void }) {
  const container = el.querySelector('.scroll-container');

  const tl = gsap.timeline({
    defaults: {
      duration: 0.34,
      ease: 'power4.inOut',
    },
    onComplete: done,
  });

  gsap.set(container, {
    opacity: 1,
    //   zIndex: 100,
    //   height: '100svh',
    //   width: '100svw',
  });

  // if (window.innerWidth < 800) {
  tl.to(container, {
    opacity: 0,
  });
  // } else {
  //   tl.fromTo(
  //     container,
  //     {
  //       // delay: 0.34,
  //       x: 0,
  //       clipPath: 'inset(0% 0% 0% 0%)',
  //       scale: 1,
  //       position: 'fixed',
  //     },
  //     {
  //       x: 0,
  //       clipPath: 'inset(5% 0% 10% 0%)',
  //       scale: 0.95,
  //       position: 'fixed',
  //     },
  //   )
  //     .to(container, {
  //       x: window.innerWidth,
  //       position: 'fixed',
  //     })
  //     .add(() => {
  //       // Set `position` back to `relative` or `static` after animation completes
  //       gsap.set(container, { position: 'relative' });
  //     });
  // }
}
