import gsap from 'gsap';

export function leaveDefault({
  el,
  isDelayed = false,
  done,
}: {
  el: Element;
  isDelayed: boolean;
  done: () => void;
}) {
  const isVs = window.innerWidth < 800;
  const delay = isDelayed ? 0.67 : 0;

  gsap.fromTo(
    el,
    {
      zIndex: -1,
    },
    {
      zIndex: -1,
      duration: 0.34,
      delay: isVs ? 0 : delay,
      onComplete: done,
    },
  );
}
