<template>
  <div
    v-if="controlPanel"
    ref="controlPanelEl"
    class="control-panel"
    :class="{ '-show-underlay': deepDiveLinkPath }"
  >
    <div class="inner -underlay">
      <NuxtLink v-if="deepDiveLinkPath" :to="deepDiveLinkPath" class="link"
        >Learn more
      </NuxtLink>
    </div>
    <div class="inner -main">
      <GridonicLogo class="-light" />
      <div class="navigations" :data-visible-nav="visibleNavigation">
        <ControlPanelNavigation
          class="nav -main"
          :links="controlPanel.mainNavigation"
        />
        <ControlPanelNavigation
          class="nav -footer"
          :links="controlPanel.footerNavigation"
        />
      </div>
      <SocialMediaNavigation :links="controlPanel.socialMediaLinks" />
    </div>
    <div class="inner -overlay">
      <GridonicLogo class="-dark" />
      <div>
        <ProgressBar :progress="deepDiveProgress" />
      </div>
      <button
        aria-label="Close Deep Dive"
        class="close"
        type="button"
        :class="{ '-floating': deepDiveProgress }"
        @click="goToPreviousRoute()"
      ></button>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import SocialMediaNavigation from '~/components/control-panel/SocialMediaNavigation.vue';
import ControlPanelNavigation from '~/components/control-panel/ControlPanelNavigation.vue';
import GridonicLogo from '~/components/control-panel/GridonicLogo.vue';
import ProgressBar from '~/components/control-panel/ProgressBar.vue';

const { deepDiveLinkPath, deepDiveProgress, goToPreviousRoute } = injectStrict(
  ControlPanelInjectionKey,
);

const route = useRoute();
const previousRoutePath = ref<null | string>(null);
const currentRoutePath = ref<null | string>(null);

const controlPanelEl = ref<HTMLElement | null>(null);

watch(
  () => route.path,
  function (newPath: string) {
    previousRoutePath.value = currentRoutePath.value;
    currentRoutePath.value = newPath;
  },
  { immediate: true },
);

const { y: windowScrollY } = useWindowScroll();

const { localeProperties } = useI18n();

const { data } = await useAsyncGql({
  operation: 'GetControlPanel',
  variables: {
    locale: localeProperties.value.siteLocale,
  },
});

const controlPanel = computed(function () {
  if (data.value?.controlPanel) {
    return data.value.controlPanel;
  }
  return null;
});

const visibleNavigation = computed(function () {
  if (
    window &&
    windowScrollY.value >= document.body.scrollHeight - window.innerHeight - 100
  ) {
    return 'footer';
  }
  return 'main';
});

onMounted(function () {
  fadeControlPanelIn();
});

function fadeControlPanelIn() {
  gsap.to(controlPanelEl.value, {
    duration: 0.34,
    y: 0,
  });
}
</script>

<style lang="scss">
html[data-scroll-direction='vertical'] {
  --clipping-overlay: inset(0 100% 0 0);
}

html[data-scroll-direction='horizontal'] {
  --clipping-overlay: inset(0 0 0 0);

  .control-panel {
    > .inner.-overlay {
      scale: 1.011;
    }
  }
}
</style>

<style scoped lang="scss">
.control-panel {
  --control-panel-height: 4.5rem;
  --control-panel-background: #282828;

  @media (--vs) {
    --control-panel-base-width: calc(100vw - 3rem);
    --control-panel-width: calc(100vw - 3rem);
  }

  @media (--vl) {
    --control-panel-base-width: 35rem;
    --control-panel-width: 35rem;
  }

  & {
    transform: translateY(10rem);
    z-index: 100;
    position: fixed;
    inset: auto 0 2rem 0;
    width: var(--control-panel-width);
    height: var(--control-panel-height);
    transition: clip-path 1250ms;
    margin-left: auto;
    margin-right: auto;
  }

  &.-show-underlay {
    > .inner.-main {
      --control-panel-width: calc(var(--control-panel-base-width) - 4rem);
    }
  }

  > .inner {
    position: relative;
    width: var(--control-panel-width);
    height: var(--control-panel-height);
    border-radius: 0.75rem;
    padding: 1rem;
    display: grid;
    align-items: center;
    gap: 1rem;
    transition: width 500ms;
  }

  /* main layer */
  > .inner.-main {
    background-color: var(--control-panel-background);
    grid-template-columns:
      calc(var(--control-panel-height) * 0.5)
      minmax(0, auto) minmax(0, auto);
    will-change: width;
    color: #ffffff;

    > .navigations {
      &[data-visible-nav='main'] {
        > .nav.-footer {
          visibility: hidden;
          display: none;
        }
      }

      &[data-visible-nav='footer'] {
        > .nav.-main {
          visibility: hidden;
          display: none;
        }
      }
    }
  }

  /* overlay layer */
  > .inner.-overlay {
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #ececec;
    grid-template-columns:
      calc(var(--control-panel-height) * 0.5)
      minmax(0, auto) calc(var(--control-panel-height) * 0.5);
    clip-path: var(--clipping-overlay);
    transition: clip-path 670ms ease-in-out;

    > .close {
      --size: 2rem;

      width: var(--size);
      height: var(--size);
      background-color: transparent;
      border: none;
      outline: none;
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12.8159' cy='12.8369' r='12' fill='%231F1F1F'/%3E%3Crect x='8.38379' y='16.1616' width='10.9689' height='1.56699' rx='0.783496' transform='rotate(-45 8.38379 16.1616)' fill='white'/%3E%3Crect width='10.9689' height='1.56699' rx='0.783496' transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 17.248 16.1616)' fill='white'/%3E%3C/svg%3E%0A");
      background-size: 100% 100%;
      border-radius: 50%;
      cursor: pointer;
      scale: 1;
      transition: scale 200ms;

      &:focus,
      &:hover {
        scale: 1.1;
        animation-play-state: paused;
      }

      &.-floating {
        animation: floating-icon 2000ms infinite;
      }
    }
  }

  /* underlay layer */
  > .inner.-underlay {
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: #474747;
    grid-template-columns: calc(var(--control-panel-height) * 0.5);
    justify-content: end;

    > .link {
      --size: 2rem;

      width: var(--size);
      height: var(--size);
      background-color: transparent;
      border: none;
      outline: none;
      background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='36' height='36' rx='18' fill='white'/%3E%3Cpath d='M12 18H24' stroke='%231F1F1F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18 24L24 18L18 12' stroke='%231F1F1F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-size: 100% 100%;
      border-radius: 50%;
      cursor: pointer;
      scale: 1;
      transition: scale 200ms;
      animation: floating-icon 2000ms infinite;
      font-size: 0;

      &:focus,
      &:hover {
        scale: 1.1;
        animation-play-state: paused;
      }
    }
  }
}

@keyframes floating-icon {
  0% {
    transform: translateX(0.15rem);
  }
  50% {
    transform: translateX(-0.15rem);
  }
  100% {
    transform: translateX(0.15rem);
  }
}
</style>
