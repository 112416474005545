import type { LinkFragment } from '#gql';

export function useInternalRoute(internalUrl: LinkFragment['internalUrl']) {
  const { locale } = useI18n();

  const path = computed(function () {
    if (!internalUrl) {
      return '/';
    }

    let linkBase = '/';

    if (locale.value !== 'de') {
      linkBase += `${locale.value}/`;
    }

    if (internalUrl.__typename === 'JobDeepDiveRecord') {
      linkBase += 'jobs/';
    }

    if (internalUrl.__typename === 'ProjectDeepDiveRecord') {
      linkBase += 'projects/';
    }

    if (internalUrl.__typename === 'BlogPostRecord') {
      linkBase += 'blog/';
    }

    const translatedSlug =
      internalUrl._allTranslatedSlugLocales?.find(
        (it) => it.locale === locale.value,
      )?.value ?? null;

    if (translatedSlug === 'home') {
      return linkBase;
    }

    return `${linkBase}${translatedSlug}`;
  });

  return { path };
}
