type ControlPanelVisibilityOptions =
  | '-faded-out'
  | '-faded-out-immediate'
  | '-faded-in'
  | '-faded-in-immediate';

interface ControlPanelInjection {
  deepDiveLinkPath: Ref<null | string>;
  deepDiveProgress: Ref<number>;
  setDeepDiveLinkPath: (linkPath: null | string) => void;
  setDeepDiveProgress: (progress: number) => void;
  visibility: Ref<ControlPanelVisibilityOptions>;
  changeVisibility: (v: ControlPanelVisibilityOptions) => void;
  goToPreviousRoute: () => void;
}

export const ControlPanelInjectionKey = injectionKey<ControlPanelInjection>();

export function useControlPanel(): ControlPanelInjection {
  const deepDiveLinkPath = ref<null | string>(null);
  const deepDiveProgress = ref<number>(0);
  const visibility = ref<ControlPanelVisibilityOptions>('-faded-out');

  const routerHistory = ref<string[]>(['/']);
  const router = useRouter();
  const route = useRoute();

  router.beforeEach((route, routeFrom) => {
    if (!routeFrom.name || !routeFrom.path) {
      return;
    }

    // don't save path for projects & jobs & blog posts
    if (String(routeFrom.name).includes('projects-id')) {
      return;
    }

    if (String(routeFrom.name).includes('jobs-id')) {
      return;
    }

    if (String(routeFrom.name).includes('blog-id')) {
      return;
    }
    routerHistory.value.push(routeFrom.path);
    window.RouterHistory = routerHistory.value;
  });

  return {
    deepDiveLinkPath,
    deepDiveProgress,
    setDeepDiveProgress,
    setDeepDiveLinkPath,
    visibility,
    changeVisibility,
    goToPreviousRoute,
  };

  function setDeepDiveLinkPath(linkPath: null | string) {
    deepDiveLinkPath.value = linkPath;
  }

  function setDeepDiveProgress(p: number) {
    deepDiveProgress.value = p;
  }

  function goToPreviousRoute() {
    const previousRoutesReversed = routerHistory.value.reverse();
    // Find the previous route that isn’t the current route
    const routeToGo = previousRoutesReversed.find((r) => r !== route.path);
    return router.push(routeToGo ?? '/');
  }
  function changeVisibility(v: ControlPanelVisibilityOptions) {
    visibility.value = v;
  }
}
