import gsap from 'gsap';

export function enterBlog({
  el,
  done,
  scrollToPosition,
}: {
  el: Element;
  done: () => void;
  scrollToPosition?: number;
}) {
  const container = el.querySelector('.scroll-container');

  const tl = gsap.timeline({
    defaults: {
      duration: 0.34,
      ease: 'power4.inOut',
    },
    onComplete: done,
  });

  window.transitionFinished = false;

  // optional scroll to a specific position
  if (scrollToPosition && container) {
    container.scrollTo(scrollToPosition, 0);
  }
  tl.set(container, {
    position: 'fixed',
    top: 0,
    zIndex: 100,
    width: '100svw',
    height: '100svh',
    overflowY: 'hidden',
  });

  // if (window.innerWidth < 800) {
  // Viewport Small
  tl.fromTo(
    container,
    {
      opacity: 0,
    },
    {
      opacity: 1,
    },
  ).add(() => {
    // Set `position` back to `relative` or `static` after animation completes
    gsap.set(container, {
      position: 'relative',
      zIndex: 'unset',
      overflowY: 'scroll',
      height: '100vh',
    });
    window.transitionFinished = true;
  });
  // } else {
  //   // Viewport Large

  //   tl.fromTo(
  //     container,
  //     {
  //       x: window.innerWidth,
  //       clipPath: `inset(5% 0% 10% 0%)`,
  //       scale: 0.95,
  //     },
  //     {
  //       x: 0,
  //       clipPath: `inset(5% 0% 10% 0%)`,
  //       scale: 0.95,
  //       position: 'fixed',
  //     },
  //   )
  //     .to(
  //       container,
  //       {
  //         clipPath: 'inset(0% 0% 0% 0%)',
  //         scale: 1,
  //         position: 'fixed',
  //       },
  //       '<52%',
  //     )
  //     .add(() => {
  //       // Set `position` back to `relative` or `static` after animation completes
  //       gsap.set(container, {
  //         position: 'relative',
  //         zIndex: 'unset',
  //         overflowY: 'scroll',
  //         height: '100vh',
  //       });

  //       window.transitionFinished = true;
  //     });
  // }
}
